import { React, useState, useEffect } from "react";
import { Outlet, useParams,Link, useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput,useRadio } from "./hooks";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const Input = styled('input')({
  display: 'none',
});

export default function ConcertPrView() {
  let {id} = useParams();
  let navigate = useNavigate();

  const { data,dataConcert,dataConcertPr,getConcertPr } = useMyData();

  const [tab_itemProps] = useRadio("jp"); // タブ


  useEffect(()=>{
    getConcertPr(id);
    window.scrollTo({
      top: 0,
      //behavior: "smooth",
    });
  },[data]);


  const convertDate = (datetime) => {
    const dt = datetime.split(' ');
    const d = new Date(dt[0]);

    const dow=['日','月','火','水','木','金','土'];
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日' + '(' + dow[d.getDay()] + ')';
  }

  // 確認画面へ
  const back = (e) => {
    e.preventDefault();

    navigate(-1);
  }

  return (
    <>
    { dataConcertPr.id ?
      <>
        <h1 className="pagetopLabel">公演情報登録(確認)</h1>

        <div className="tabs">
          <input id="jp" type="radio" name="tab_item" {...tab_itemProps} value="jp" checked={tab_itemProps.val=="jp"} /><label className="tab_item" htmlFor="jp">公演情報（和文）</label>
          <input id="eng" type="radio" name="tab_item" {...tab_itemProps} value="eng" checked={tab_itemProps.val=="eng"} /><label className="tab_item" htmlFor="eng">公演情報（英文）</label>

          <div className="tab_content" id="jp_content">
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>掲載希望</th>
                <td>{dataConcertPr.publish_for==2 && <>公式ウェブサイト及び紙面版コンサートカレンダーへの掲載を希望する</>}
                    {dataConcertPr.publish_for==1 && <>公式ウェブサイトのみの掲載を希望する</>}
                    {dataConcertPr.publish_for==0 && <>掲載を希望しない</>}
                  </td>
              </tr>
              <tr>
                <th>公演日・曜日</th>
                <td>{convertDate(dataConcertPr.sdate)}</td>
              </tr>
              <tr>
                <th>開場時間/開演時間/終演時間 </th>
                <td>
                  開場 {dataConcertPr.open_time.replace(/(\d+):(\d+):00/, '$1:$2')}
                  &nbsp;／&nbsp;
                  開演 {dataConcertPr.start_time.replace(/(\d+):(\d+):00/, '$1:$2')}
                  &nbsp;／&nbsp;
                  終演 {dataConcertPr.end_time.replace(/(\d+):(\d+):00/, '$1:$2')}
                </td>
              </tr>
              <tr>
                <th>会場</th>
                <td>
                  {dataConcertPr.venue==10 ? '大ホール' : ''}
                  {dataConcertPr.venue==20 ? '小ホール' : ''}
                  {dataConcertPr.venue==30 ? 'レセプションルーム' : ''}
                  {dataConcertPr.venue==99 ? 'その他' : ''}
                </td>
              </tr>
              <tr>
                <th>公演名</th>
                <td>{dataConcertPr.title_ja}</td>
              </tr>
              <tr>
                <th>主催/協力/貸館</th>
                <td>
                {dataConcertPr.type==0 ? '主催公演' : ''}
                {dataConcertPr.type==1 ? '協力公演' : ''}
                {dataConcertPr.type==2 ? '貸館公演' : ''}
                </td>
              </tr>
              <tr>
                <th>出演者/演奏団体</th>
                <td>
                  { dataConcertPr.playerJa && dataConcertPr.playerJa.map((player) => (
                    <div key={player.id}>
                      {player.name}
                      &nbsp;／&nbsp;
                      {player.part}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { dataConcertPr.programJa && dataConcertPr.programJa.map((program) => (
                    <div key={program.id}>
                      {program.author}
                      &nbsp;／&nbsp;
                      {program.title}
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット種別</th>
                <td>
                  {dataConcertPr.ticket_type==10 && <>全席指定</>}
                  {dataConcertPr.ticket_type==20 && <>全席自由</>}
                  {dataConcertPr.ticket_type==30 && <>当日座席指定</>}
                  {dataConcertPr.ticket_type==40 && <>関係者のみ</>}
                  {dataConcertPr.ticket_type==99 && <>その他 {dataConcertPr.ticket_type_text_ja}</>}
                </td>
              </tr>
              <tr>
                <th>入場料金</th>
                <td>
                {dataConcertPr.charge==1 && <>有料</>}
                {dataConcertPr.charge==0 && <>無料</>}
                  <br />
                  { dataConcertPr.priceJa && dataConcertPr.priceJa.map((price) => (
                  <div key={price.id}>
                    {price.name}
                      &nbsp;／&nbsp;
                    {Number(price.price).toLocaleString() }円
                  </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット販売方法</th>
                <td>
                  {dataConcertPr.ticket_sale==10 && <>チケットぴあに販売委託（ホールチケットセンター（窓口･電話･WEB）を含む）<br /><span>※チケットぴあに委託された場合、ホールチケットセンターでも取扱いとなります。</span></>}
                  {dataConcertPr.ticket_sale==20 && <>ホールチケットセンター委託販売（ホールチケットセンター窓口のみ）</>}
                  {dataConcertPr.ticket_sale==30 && <>主催者のみの販売、またはチケットぴあ以外のチケット会社に委託</>}
                  {dataConcertPr.ticket_sale==99 && <>その他 {dataConcertPr.ticket_sale_text_ja}</>}
                </td>
              </tr>
              <tr>
                <th>チケット販売・受付開始日</th>
                <td>{dataConcertPr.ticket_sale_date && convertDate(dataConcertPr.ticket_sale_date)}</td>
              </tr>
              <tr>
                <th>未就学児の入場</th>
                <td>
                  {dataConcertPr.children_enter==30 && <>未就学児入場不可</>}
                  {dataConcertPr.children_enter==10 && <>未就学児入場可【チケット要】</>}
                  {dataConcertPr.children_enter==20 && <>未就学児入場可【チケット不要】</>}
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  {dataConcertPr.url_ja}
                </td>
              </tr>
              { (dataConcertPr.type==0 || dataConcertPr.type==1) &&
              <tr>
                <th>画像･イメージ</th>
                <td>
                  {dataConcertPr.file ?
                  <a href={process.env.REACT_APP_API_ENDPOINT + 'my/concert/' + id + '/image/' + dataConcertPr.file}>ダウンロード</a>
                  : ''}
                </td>
              </tr>
              }
              <tr>
                <th>その他</th>
                <td>
                  <pre>{dataConcertPr.comment}</pre>
                </td>
              </tr>
              <tr>
                <th>問合せ先</th>
                <td>{dataConcertPr.contact_ja}</td>
              </tr>
              <tr>
                <th>問合せ先電話番号</th>
                <td>{dataConcertPr.phone && <>{dataConcertPr.phone}</>}</td>
              </tr>
              <tr>
                <th>問合せ先メールアドレス</th>
                <td>
                    {dataConcertPr.email}
                </td>
              </tr>
            </tbody>
            </table>

            <h6>入力者連絡先</h6>
            <table className="Form" summary="#">
              <tbody><tr>
                <th>団体名 </th>
                <td>{dataConcertPr.inputter_org}</td>
              </tr>
              <tr>
                <th>担当者名 </th>
                <td>
                  {dataConcertPr.inputter_name_l}
                  &nbsp;
                  {dataConcertPr.inputter_name_f}
                </td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>
                    {dataConcertPr.inputter_mail}
                </td>
              </tr>
              <tr>
                <th> TEL </th>
                <td>{dataConcertPr.inputter_phone && <>{dataConcertPr.inputter_phone}</>}</td>
              </tr>
            </tbody>
            </table>
          </div>

          <div className="tab_content" id="eng_content"><br />
            <p>ウェブサイト公演カレンダー（英語版）に掲載希望の場合は入力してください。</p>
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>公演名</th>
                <td>{dataConcertPr.title_en}</td>
              </tr>
              <tr>
                <th>出演者/演奏団体</th>
                <td>
                  { dataConcertPr.playerEn && dataConcertPr.playerEn.map((player) => (
                    <div key={player.id}>
                      {player.name}
                      &nbsp;／&nbsp;
                      {player.part}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { dataConcertPr.programEn && dataConcertPr.programEn.map((program) => (
                    <div key={program.id}>
                      {program.author}
                      &nbsp;／&nbsp;
                      {program.title}
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット種別</th>
                <td>
                  {dataConcertPr.ticket_type==10 && <>全席指定</>}
                  {dataConcertPr.ticket_type==20 && <>全席自由</>}
                  {dataConcertPr.ticket_type==30 && <>当日座席指定</>}
                  {dataConcertPr.ticket_type==40 && <>関係者のみ</>}
                  {dataConcertPr.ticket_type==99 && <>その他 {dataConcertPr.ticket_type_text_en}</>}
                </td>
              </tr>
              <tr>
                <th>入場料金</th>
                <td>
                  {dataConcertPr.charge==1 && <>有料</>}
                  {dataConcertPr.charge==0 && <>無料</>}
                  <br />
                  { dataConcertPr.priceEn && dataConcertPr.priceEn.map((price) => (
                  <div key={price.id}>
                    {price.name}
                      &nbsp;／&nbsp;
                    {Number(price.price).toLocaleString() }yen
                  </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  {dataConcertPr.url_en}
                </td>
              </tr>
              <tr>
                <th>問合せ先</th>
                <td>{dataConcertPr.contact_en}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>

        <div className="Form__btnbox">
          <div className="Form__btn-return">
            <input value="戻る" type="submit" id="back" onClick={back} />
          </div>
        </div>

      </>
    : '' }
    </>
 );
}
